import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'reports/'

const initialState = {
    gcd: [],
    bwd: [],
    mwd: [],
    idata: [],
    idd: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getCategoryData = createAsyncThunk('fdr/gcd', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getCategoryData')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getBrandWiseData = createAsyncThunk('fdr/gbd', async (category, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getBrandWiseData', category)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getModelWiseData = createAsyncThunk('fdr/gmd', async (category, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getItemModelData', category)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getItemData = createAsyncThunk('fdr/gid', async (category, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getItemData', category)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getItemDetailsData = createAsyncThunk('fdr/gidd', async (itemURL, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getItemDetailsData', itemURL)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const fetchedDataSlice = createSlice({
    name: 'fdr',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
            state.gcd = []
            state.bwd = []
            state.mwd = []
            state.idata = []
            state.idd = []
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCategoryData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.gcd = action.payload
            })
            .addCase(getCategoryData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.gcd = null
            })
            .addCase(getBrandWiseData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBrandWiseData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bwd = action.payload
            })
            .addCase(getBrandWiseData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.bwd = null
            })
            .addCase(getModelWiseData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getModelWiseData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mwd = action.payload
            })
            .addCase(getModelWiseData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.mwd = null
            })
            .addCase(getItemData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getItemData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.idata = action.payload
            })
            .addCase(getItemData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.idata = null
            })
            .addCase(getItemDetailsData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getItemDetailsData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.idd = action.payload
            })
            .addCase(getItemDetailsData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.idd = null
            })
    }
})

export const { reset } = fetchedDataSlice.actions

export default fetchedDataSlice.reducer